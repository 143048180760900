<!-- 免试库 -->
<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/admin.css";
</style>
<style type="text/css" scoped>
  /deep/ .el-form-item__content {
    line-height: inherit;
  }

  /deep/.el-form-item {
    margin-bottom: 0;
  }

  /deep/.el-pagination__jump {
    margin-left: 0;
    margin-right: 10px;
  }

  .table {
    line-height: 20px;
  }
</style>
<script>
  import Layout from "@/views/layouts/main";
  import BaseHeader from "@/components/base-header";
  import {
    getExamFree,
    deleteExamFree,
    addExamFree,
    editExamFree,
    getExamFreeDetail
  } from "@/api/admin/base/examFree.js"
  import {
    easyList,
    SecondEasyList
  } from "@/api/admin/base/mainType.js"
  import {
    classificationEasyList,
    getDetail
  } from "@/api/admin/base/classification.js"
  import {
    // getSubListEasy
  } from "@/api/admin/base/subject.js"

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      BaseHeader,
    },
    data() {
      return {
        items: [{
            text: "用户首页",
            href: "/admin"
          },
          {
            text: "考试免试库",
            active: true
          }
        ],
        exportTitle: "考试免试库导出",
        importTitle: "考试免试库导入",
        showmodal: false,
        dialogTitle: "添加免试",
        module: "MSK",
        tableList: [],
        allDataList: [],
        checkList: [],
        disabled: false,
        isIndeterminate: false,
        allCheck: false,
        dict: {},
        addForm: {

        },
        pageData: {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          lbbm: "",
          flbm: "",
          xkdm: "",
        },
        classList: [],
        secondList: [],
        classificationList: [],
        subjectList: []
      };
    },
    methods: {
      // 删除
      deleteItem(sid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteExamFree(sid).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据删除成功!'
              });
            }
            this.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      // 获取数据列表
      getList() {
        getExamFree(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 添加类别
      addExamFree() {
        this.getkmmc()
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sfkqk = 0
        if (this.addForm.subjectBeanList.length) {
          addExamFree(formDate).then(res => {
            if (res.status) {
              this.success()
            }

          })
        } else {
          this.$message({
            type: 'warning',
            message: '免试科目不能为空!'
          });
        }

      },
      // 修改
      editExamFree() {
        this.getkmmc()
        let formDate = JSON.parse(JSON.stringify(this.addForm))
        formDate.sid = this.addForm.sid
        formDate.sfkqk = 0
        // FormData.subjectBeanList = this.checkList
        editExamFree(formDate).then(res => {
          if (res.status) {
            this.success()
          }
        })
      },
      success() {
        this.$notify({
          type: 'success',
          message: '数据操作成功!',
          duration: 3000,
        })

        this.showmodal = false;
        this.getList()
      },
      addItem() {
        this.showmodal = true
        this.dialogTitle = "添加免试"
        this.getBm(this.module, "ms").then(e => {
          this.addForm.msdm = e
        })
        this.addForm = {}
        this.checkList = []
        this.subjectList = []
        this.allCheck = false

      },
      editItem(data) {
        let copyData = JSON.parse(JSON.stringify(data))
        this.addForm = copyData
        this.showmodal = true
        this.dialogTitle = "修改免试"
        this.disabled = true
        let flbm = copyData.flbm
        this.checkList = []
        this.getClassList(flbm)
        this.getxkmc().then(() => {
          this.handleChecked()
        });
        getExamFreeDetail(copyData.msdm).then(res => {
          if (res.status) {
            let subList = res.data.subjectBeanList
            subList.forEach(k => {
              this.checkList.push(k.kmdm)
            })
            this.handleChecked()
          }

        })

      },
      submit() {
        if (this.addForm.sid) {
          this.editExamFree()
        } else {
          this.addExamFree()
        }
      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },


      // 考试类别
      getClassListPage() {
        easyList().then(res => {
          if (res.status) {
            this.classList = res.data
          }
        })
      },
      // 二级分类
      getSecondList(params) {
        SecondEasyList(params).then(res => {
          if (res.status) {
            this.secondList = res.data
          }
        })
      },
      // 选科筛选
      getClassList(params) {
        classificationEasyList({
          flbm: params,
          sfms: 1
        }).then(res => {
          if (res.status) {
            this.classificationList = res.data
          }
        })
      },
      // 科目
      getflmc() {
        let val = this.addForm.flbm
        let obj = this.secondList.find(k => {
          return k.flbm === val
        })
        this.addForm.flmc = obj.flmc
        this.getClassList(val)

      },
      getxkmc() {
        let val = this.addForm.xkdm
        let obj = this.classificationList.find(k => {
          return k.xkdm === val
        })
        this.addForm.xkmc = obj.xkmc
        let sid = obj.sid
        return getDetail(sid).then(res => {
          if (res.status) {
            this.subjectList = res.data.subjectList
          }

        })

      },
      getkmmc() {
        let subjectBeanList = []
        for (let i = 0; i < this.checkList.length; i++) {

          let obj = this.subjectList.find(k => {
            return k.kmdm == this.checkList[i]
          })
          if (obj) {
            subjectBeanList.push({
              "kmdm": this.checkList[i],
              "kmmc": obj.kmmc,
            })
          }


        }
        this.addForm.subjectBeanList = subjectBeanList
      },
      getlbmc() {
        let val = this.addForm.lbdm
        let obj = this.classList.find(k => {
          return k.lbbm === val
        })
        this.addForm.lbmc = obj.lbmc
        this.getSecondList({
          lbbm: val
        });
      },
      // 科目选择
      checkAll(e) {
        this.checkList = e ? this.subjectList.map(item => {
          return item.kmdm
        }) : [];
        if (this.checkList.length == 0) {
          this.allCheck = false
        } else {
          this.allCheck = true
        }
        this.isIndeterminate = false
      },
      handleChecked() {
        let max = this.subjectList.length;
        let now = this.checkList.length;
        this.isIndeterminate = false
        if (now == 0) {
          this.allCheck = false
        } else if (now == max) {
          this.allCheck = true
        } else {
          this.isIndeterminate = true
        }

      },
      uploadSuccess() { // 数据导入之后刷新列表
        this.getList()
      }
    },
    mounted() {
      this.getList()
      this.getClassListPage()
      this.getSecondList()
      this.getClassList()
    }

  };
</script>

<template>
  <Layout>
    <BaseHeader :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 600px;">
          <div class="card-body">
            <div class="pb-3 border-dash check-table-top">
              <div class="flexList">
                <b-button @click="addItem" variant="outline-info" class="flexList mr-2 condition"><i
                    class="iconfont icon-plus-circle1 mr-2 font-size-20"></i>添加免试</b-button>
                <a href="/admin/general">
                  <b-button variant="info" class="flexList mr-2 ">报考条件</b-button>
                </a>
                <el-button size="small" type="primary" plain class="flexList mr-2 h30 "
                  @click="$importModal().show({title: importTitle, module:module,success:uploadSuccess})"><i
                    class="iconfont icon-riLine-upload-2-line mr-2"></i>{{importTitle}}</el-button>
              </div>
              <div class="d-flex">
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type: 'xlsx', module:module})"><i
                    class="iconfont icon-antOutline-file-excel mr-2"></i>excel</div>
                <div class="border-blue export-tab"
                  @click="$exportModal().show({title: exportTitle, type:'dbf', module:module})"><i
                    class="iconfont icon-data mr-2"></i>dbf</div>
              </div>
            </div>
            <div class="table-responsive border mt-3">
              <table class="table  light-table table-hover ">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 3%;">序号</th>
                    <th>类别名称</th>
                    <th style="width: 10%;">考试二级类别</th>
                    <th style="width:5%;">免试编码</th>
                    <th style="width: 6%;">填写类型</th>
                    <th>专业名称</th>
                    <th style="width: 30%;">免试原因</th>
                    <th style="width: 12%;">免试科目名称</th>
                    <th style="width: 6%;">修改人</th>
                    <th style="width: 10%;">修改时间</th>
                    <th style="width: 4%;">操作</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(obj,index) in tableList" :key="index">
                    <td>{{index+1+(pageData.pageNum-1)*pageData.pageSize}}</td>
                    <td>{{obj.lbmc}}</td>
                    <td>{{obj.flmc}}</td>
                    <td>{{obj.msdm}}</td>
                    <td>
                      <div class="font-blue" @click='editItem(obj)'>{{obj.txlx==1?'证书':'学历'}}</div>
                    </td>
                    <td> {{obj.xkmc}}</td>
                    <td>
                      <div class="line2">{{obj.msyy}}</div>
                    </td>
                    <td>
                      <div style="margin-right: 5px;">
                        {{obj.mskmmc}}
                      </div>

                    </td>
                    <td>{{obj.modifyUser}}</td>
                    <td>{{timeSlice(obj.modifyTime)}}</td>
                    <td class="tab-icon"><i class="iconfont icon-edit-two align-middle mr-1"
                        @click='editItem(obj)'></i><i class="iconfont icon-riLine-delete-bin-line align-middle mr-1"
                        @click="deleteItem(obj.sid,obj.msdm)"></i>
                    </td>
                  </tr>


                </tbody>
              </table>
            </div>
            <div class="float-right d-flex ">
              <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
                layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
              </el-pagination>

            </div>

          </div>
        </div>
      </div>


    </div>

    <!-- 弹窗开始 -->
    <!-- 添加类别 -->
    <b-modal id="addClass" v-model="showmodal" centered :title="this.dialogTitle" title-class="font-18" hide-footer>
      <div>
        <div class="grey-time mb-3">说明：免试库主要针对职业资格类考试，通过了其他资格考试，可以申请免试若干科。</div>
        <el-form ref="addForm" :model="addForm">
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>类别名称：</label>
              <div class=" col-sm-10 p-0 flexList">
                <select name="lbbm" class="form-control form-select w-100" v-model="addForm.lbdm" @change="getlbmc">
                  <option value="" disabled>请选择所属类别</option>
                  <option :value="item.lbbm" v-for="(item,index) in classList" :key="index">{{item.lbmc}}</option>
                </select>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>二级类别：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.flbm" @change="getflmc">
                  <option value="" disabled>请选择分类</option>
                  <option :value="item.flbm" v-for="(item,index) in secondList" :key="index">{{item.flmc}}</option>
                </select>
              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>免试编码：</label>
              <div class="col-sm-10  p-0">
                <input type="text" name="" id="" maxlength="5" :disabled="disabled" placeholder="免试编码 预置可修改"
                  v-model="addForm.msdm" class="form-control w-100 h30 " />
              </div>
            </div>
          </el-form-item>
          <!--  <el-form-item>
            <div class="d-flex check-distri mb-3"><label>免试类别：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.mslb">
                  <option value="" disabled>请选择免试类别</option>
                  <option value="免试一科">免试一科
                  </option>
                  <option value="免试两科">免试两科

                  </option>
                </select>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item>
            <div class="flexList check-distri mb-3"><label class="mb-0">填写类型：</label>
              <div class="col-sm-10 p-0 flexList">
                <div class="form-check mr-2"><input type="radio" name="txlx" id="exampleRadios1" v-model="addForm.txlx"
                    value="1" class="form-check-input"><label for="exampleRadios1" class="form-check-label">证书
                  </label></div>
                <div class="form-check mr-2"><input type="radio" name="txlx" id="exampleRadios1" v-model="addForm.txlx"
                    value="2" class="form-check-input"><label for="exampleRadio1" class="form-check-label">学历</label>
                </div>

              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>专业名称：</label>
              <div class="col-sm-10  p-0">
                <select name="" class="form-control form-select w-100" v-model="addForm.xkdm" @change="getxkmc">
                  <option value="" disabled>请选择专业</option>
                  <option :value="item.xkdm" v-for="item in classificationList" :key="item.value">
                    {{item.fjxkmc==''?item.xkmc:'【'+item.fjxkmc+'】'+item.xkmc}}
                    {{item.jbmc?'【'+item.jbmc+'】':''}}
                  </option>
                </select>

              </div>

            </div>
          </el-form-item>
          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>免试科目：</label>
              <div class="col-sm-10  p-0" style="height: 80px;overflow-y: auto;">
                <el-checkbox :indeterminate="isIndeterminate" @change="checkAll" class="mr-1 d-block" v-model="allCheck"
                  v-if="subjectList.length">全选</el-checkbox>
                <el-checkbox-group v-model="checkList" @change="handleChecked">
                  <el-checkbox v-for="item in subjectList" :key="item.value" :value='item.kmdm' :label="item.kmdm">
                    {{item.kmmc}}
                  </el-checkbox>
                </el-checkbox-group>

              </div>

            </div>
          </el-form-item>

          <el-form-item>
            <div class="d-flex check-distri mb-3"><label>免试原因：</label>
              <div class="col-sm-10  p-0">
                <textarea rows="3" placeholder="请输入免试原因" maxlength="200" v-model="addForm.msyy"
                  style="overflow-y: auto;" class="form-control w-100  " />
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <div class=" mt-3  text-center"><button type="button" class="btn btn-info h30 w-md mr-3"
          @click="submit">确定</button>
        <button type="button" class="btn btn-secondary h30  w-md" @click="showmodal = false">取消</button>
      </div>



    </b-modal>
    <!-- 弹窗结束-->
  </Layout>
</template>
